import { Modal } from 'antd'
import popup from '../../../../../assets/popup.png'
import './style.css'

const Popup = (props) => {

    const { modalShow, setModalShow } = props

    return (    
        <Modal 
        open={modalShow}
        onCancel={() => setModalShow(false)}
        footer={(_, { OkBtn, CancelBtn }) => null}
        width={600}
        className='popup'
        >
            <img src={popup} onClick={() => setModalShow(false)}/>
        </Modal>
    )
}

export default Popup