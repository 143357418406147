export const convertSecondsToDayjs = (seconds) => {
    let now = dayjs();
    let startOfToday = dayjs().startOf('day');
    let result = startOfToday.add(seconds / 1000, 'second');

    return result;
}

const dayjs = require('dayjs')

const convert = (point) => {
    if(point.officeList.length) {
        const tmp = point.officeList.map(of => of.name)
        return point.description ? `${point.description} ${tmp.join(', ')}` : `${tmp.join(',')}` 
    } else {
        return point.description ? `${point.description}` : null
    }

}

export default convert