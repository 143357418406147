import EndPoint from "../common/endpoints";
import { ApiAdminConfig, ApiConfig } from "./config";

export const apiLogin = async (payload) => {
    return ApiConfig(EndPoint.LOGIN, payload)
}

//admin
export const apiGetListNews = async () => {
    return ApiConfig(EndPoint.LIST_NEWS, undefined, "GET")
}

export const apiGetMediaContent = async (type) => {
    return ApiConfig(`${EndPoint.LIST_MEDIA_CONTENT}?type=${type}`, undefined, "GET")
}


//global
export const apiGetListProvince = async () => {
    return ApiConfig(EndPoint.LIST_PROVINCE, undefined, "GET")
}

export const apiGetListDistrict = async (p) => {
    return ApiConfig(`${EndPoint.LIST_DISTRICT}?provinceId=${p}`, undefined, "GET")
}

export const apiGetPointList = async () => {
    return ApiConfig("/global/get-point-list", undefined, "GET")
}

export const apiGetLocation = async (p) => {
    return ApiConfig(`/global/get-location?locationId=${p}`, undefined, "GET")
}

export const apiGetCoaches = async () => {
    return ApiConfig("/global/get-coaches", undefined, "GET")
}

export const apiGetListCompanyGb = async () => {
    return ApiConfig("/global/get-company-list", undefined, "GET")
}

export const apiOrderCoach = async (props) => {
    return ApiConfig("/global/order-coach", props)
}

export const apiGetNewsFeedById = async (newsId) => {
    return ApiConfig(`/global/get-newsfeed-by-id?id=${newsId}`, undefined, "GET")
}

export const apiFindCoach = async (props) => {
    return ApiConfig(EndPoint.FIND_COACH, props)
}

export const apiSubmitRequest = async (props) => {
    return ApiConfig("/global/submit-request", props)
}

export const apiListOfficeByUser = async (id) => {
    return ApiConfig(`/global/get-company-office-list?companyId=${id}`, undefined, "GET")
}

export const apiGetPickUpPoint = async (id) => {
    return ApiConfig(`/global/get-pick-up-list?dropOffPointId=${id}`, undefined, "GET" )
}

export const apiGetDropOffPoint = async (id) => {
    return ApiConfig(`/global/get-drop-off-list?pickUpPointId=${id}`, undefined, "GET" )
}

export const apiChooseSeat = async(props) => {
    return ApiConfig('/global/choose-seat', props)
}

export const apiUnChooseSeat = async(props) => {
    return ApiConfig('/global/un-choose-seat', props)
}


export const apiGetSeatList = async (id) => {
    return ApiConfig(`/global/get-seat-list?timeslotId=${id}`, undefined, "GET")
}

export const apiUpdateStatistic = async (props) => {
    return ApiConfig('/global/update-statistic', props)
}