import { Card, Button, Typography, Row, Col } from "antd";
import { PhoneFilled, ClockCircleOutlined } from "@ant-design/icons";
import { IconCar, IconTP, MiniBlue, MiniRed } from "../../../../../assets/svgs/index";
import { regexNumber } from "../../../../../utils/regex";
import { convertSecondsToDayjs } from "../../../../../utils/convert";
import { useState } from "react";
const { Title } = Typography;
import dayjs from "dayjs";
import { apiListOfficeByUser, apiUpdateStatistic } from "../../../../../api/services";
import "./style.css";
import ModalOffice from "../ModalOffice";
import ModalTravelPath from "../ModalTravelPath";
import OfficeAtPoint from "../OfficeAtPoint";

const CardCoach = (props) => {
	const { coach, setModalShow, setCurrentCoach } = props;
	const [detail, setDetail] = useState(false);
	const [officeShow, setOfficeShow] = useState(false);
	const [startPointOffice, setStartPointOffice] = useState(false);
	const [endPointOffice, setEndPointOffice] = useState(false);
	const [officeData, setOfficeData] = useState();
	const [tpShow, setTpShow] = useState(false);

	const handleLoadListOffice = async (id) => {
		const res = await apiListOfficeByUser(id);
		if (!res.data.error) {
			setOfficeShow(true);
			setOfficeData(res.data.data);
		}
	};

	const handleUpdateStatistic = async () => {
		const res = await apiUpdateStatistic({
			coachCompanyId: coach.coachCompany.id,
			isHotlineClicked: true
		})
	}

	return (
		<div className="mobile:w-full">
			<Card
				title={
					<div>
						<div className="space-x-3 grid grid-cols-12 mt-4 items-center">
							<img src={coach.coachCompany.logo} className="rounded-full col-span-1" style={{
								minWidth: "56px",
								minHeight: "56px",
							}} />
							<div className="flex flex-col col-span-8">
								<Title level={4}>{coach.coachCompany.name}</Title>
								<a href={`tel:${coach.coachCompany.hotline}`} onClick={() => handleUpdateStatistic()}>
									<PhoneFilled /> Hotline: {coach.coachCompany.hotline}
								</a>
							</div>
							<div className="justify-end col-span-3 mt-4">
								<a className="text-green" onClick={() => handleLoadListOffice(coach.coachCompany.id)}>
									Thông tin
								</a>
							</div>
						</div>
						<div className="border border-dashed border-green mt-3 w-11/12 ju" />
					</div>
				}
				className="mobile:hidden desktop:block p-3 flex flex-col">
				<div className="flex flex-row mt-6">
					<div className="w-3/4">
						<Row>
							<Col>
								<Title style={{ color: "#006D38" }}>{dayjs(convertSecondsToDayjs(coach.departureTime)).format("HH:mm")}</Title>
							</Col>
							<Col>
								<Row className="mx-2 space-x-2 flex flex-row items-center">
									<IconCar />
									<p className="text-base font-bold">{coach.coach.coachType?.name}</p>
								</Row>
								<Row className="mx-2.5 space-x-2 flex flex-row items-center">
									<ClockCircleOutlined />
									<p className="text-base font-bold">
										{`${coach.sectionId ? "Xe sẽ đón +/- 20 phút" : `Xuất bến ${coach.startPoint.location.district}`}`}
									</p>
								</Row>
							</Col>
						</Row>
						<div className="space-x-1 flex flex-row items-center">
							<IconTP />
							<div className="font-extrabold">Lộ trình:</div>
							{detail ? <p>{coach.travelPath.detail}</p> : <p>{coach.travelPath.name}</p>}
							<p className="text-green cursor-pointer" onClick={() => setTpShow(true)}>
								Chi tiết
							</p>
						</div>
					</div>

					<div className="w-1/4">
						<div className="flex flex-col justify-end">
							<Title level={3}>{regexNumber(coach.price)}đ</Title>
							<Button
								className="w-3/4"
								onClick={() => {
									setModalShow(true);
									setCurrentCoach(coach);
								}}>
								Đặt vé
							</Button>
						</div>
					</div>
				</div>
				<div>
					<div className="space-x-2 items-center flex flex-row">
						<MiniBlue />
						<div className="truncate flex flex-row space-x-2 chi-tiet">
							<p className="font-extrabold">{coach.startPoint.location.district}:</p>
							<p className="">{coach.startPoint.description} </p>
						</div>
						<a className="text-green" onClick={() => setStartPointOffice(true)}>
							Xem thêm
						</a>
					</div>
					<div className="space-x-2 items-center flex flex-row">
						<MiniRed />
						<div className="truncate flex flex-row space-x-2 chi-tiet">
							<p className="font-extrabold">{coach.endPoint.location.district}:</p>
							<p className="">{coach.endPoint.description}</p>
						</div>
						<a className="text-green" onClick={() => setEndPointOffice(true)}>
							Xem thêm
						</a>
					</div>
				</div>
			</Card>

			<Card
				className="mobile:block mobile:w-full desktop:hidden mobile"
				title={
					<div>
						<div className="flex flex-row h-24">
							<img src={coach.coachCompany.logo} className="w-16 h-16 rounded-full m-2 mt-7" />
							<div className="flex flex-col h-12 mt-7">
								<h1 className="font-bold">{coach.coachCompany.name}</h1>
								<a href={`tel:${coach.coachCompany.hotline}`} onClick={() => handleUpdateStatistic()}>
									<PhoneFilled /> Hotline: {coach.coachCompany.hotline}
								</a>
							</div>
						</div>
					</div>
				}
				extra={
					<a className="text-background mr-4" onClick={() => handleLoadListOffice(coach.coachCompany.id)}>
						Thông tin
					</a>
				}>
				<div className="flex flex-col">
					<div className="border border-dashed border-green w-full mt-4" />
					<div className="flex flex-row items-center h-12">
						<Title className="w-1/3 mt-3" style={{ color: "#006D38" }}>
							{dayjs(convertSecondsToDayjs(coach.departureTime)).format("HH:mm")}
						</Title>
						<div className="w-2/3 flex flex-col">
							<div className="space-x-2 flex flex-row items-center">
								<IconCar />
								<p className="text-xs font-bold">{coach.coach.coachType?.name}</p>
							</div>
							<div className="ml-0.5 space-x-2 flex flex-row items-center">
								<ClockCircleOutlined />
								<p className="text-xs font-bold">{`${
									coach.sectionId ? "Xe sẽ đón +/- 20 phút" : `Xuất bến ${coach.startPoint.location.district}`
								}`}</p>
							</div>
						</div>
					</div>
					<div className="space-x-1 flex flex-row items-center">
						<IconTP />
						<div className="font-bold">Lộ trình: </div>
						{detail ? (
							<div className="overflow-scroll w-48">
								<p className="">{coach.travelPath.detail}</p>
							</div>
						) : (
							<p className="truncate">{coach.travelPath.name}</p>
						)}
						<p className="text-green cursor-pointer" onClick={() => setTpShow(true)}>
							Chi tiết
						</p>
					</div>
					<div className="space-x-1 flex flex-row items-center">
						<MiniBlue />
						<div className="truncate flex flex-row space-x-2 chi-tiet-mobile">
							<p className="font-extrabold">{coach.startPoint.location.district}:</p>
							<p className="">{coach.startPoint.description} </p>
						</div>
						<a className="text-green" onClick={() => setStartPointOffice(true)}>
							Xem thêm
						</a>
					</div>
					<div className="space-x-1 flex flex-row items-center">
						<MiniRed />
						<div className="truncate flex flex-row space-x-2 chi-tiet-mobile">
							<p className="font-extrabold">{coach.endPoint.location.district}:</p>
							<p className="">{coach.endPoint.description}</p>
						</div>
						<a className="text-green" onClick={() => setEndPointOffice(true)}>
							Xem thêm
						</a>
					</div>

					<div className="">
						<div className="flex flex-row h-8">
							<Title className="w-3/4" level={3}>
								{regexNumber(coach.price)}đ
							</Title>
							<Button
								className="w-1/4 flex justify-center"
								onClick={() => {
									setModalShow(true);
									setCurrentCoach(coach);
								}}>
								Đặt vé
							</Button>
						</div>
					</div>
				</div>
			</Card>
			{officeShow && (
				<ModalOffice
					data={officeData}
					name={coach.coachCompany.name}
					logo={coach.coachCompany.logo}
					hotline={coach.coachCompany.hotline}
					modalShow={officeShow}
					setModalShow={setOfficeShow}
				/>
			)}
			{tpShow && <ModalTravelPath modalShow={tpShow} setModalShow={setTpShow} tp={coach.travelPath.detail} />}
			{startPointOffice && <OfficeAtPoint data={coach.startPoint} modalShow={startPointOffice} setModalShow={setStartPointOffice} />}
			{endPointOffice && <OfficeAtPoint data={coach.endPoint} modalShow={endPointOffice} setModalShow={setEndPointOffice} />}
		</div>
	);
};

export default CardCoach;
