import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiFindCoach, apiGetListCompanyGb, apiGetDropOffPoint, apiGetListNews, apiGetPickUpPoint } from "../../api/services";

const initialState = {
    result: [],
    filter: false,
    optionsPickup: [],
    optionsDropoff: [],
    listCompany: [],
    newsFeed: [],
    search: {},
    loading: false
};

export const requestFindCoach = createAsyncThunk("/global/find-coach", async (props) => {
	const res = await apiFindCoach(props);
	return res.data.data;
});

export const requestLoadOptionsPickup = createAsyncThunk("/global/pick-up-point", async(id) => {
  const res = await apiGetPickUpPoint(id);
		const options = res.data.data.map((tmp) => ({
			value: tmp.province.provinceId,
			label: tmp.province.province,
			children: tmp.districtList.map((d) => ({
				value: d.districtId,
				label: d.district,
			})),
		}));

  return options
})

export const requestLoadOptionsDropoff = createAsyncThunk("/global/drop-off-point", async(id) => {
  const res = await apiGetDropOffPoint(id);
		const options = res.data.data.map((tmp) => ({
			value: tmp.province.provinceId,
			label: tmp.province.province,
			children: tmp.districtList.map((d) => ({
				value: d.districtId,
				label: d.district,
			})),
		}));

  return options
})

export const requestLoadCompany = createAsyncThunk("/global/get-list-company", async () => {
	const res = await apiGetListCompanyGb();
	return res.data.data;
});

export const requestLoadNewsFeed = createAsyncThunk("/global/get-news-feed", async () => {
	const res = await apiGetListNews();
	return res.data.data.content;
});

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setCurrentSearch: (state, action) => {
            state.search = action.payload
        },
        setFilter: (state, action) => {
          state.filter = action.payload
        }
    },
    extraReducers: (builder) => {
      builder.addCase(requestFindCoach.fulfilled, (state, action) => {
        state.filter = false
        state.result = action.payload.routeList
        state.listCompany = action.payload.companyList
      })
      builder.addCase(requestLoadCompany.fulfilled, (state, action) => {
        state.loading = false
        // state.listCompany = action.payload
      })
      builder.addCase(requestLoadNewsFeed.fulfilled, (state, action) => {
        state.loading = false
        state.newsFeed = action.payload
      })
      builder.addCase(requestLoadOptionsPickup.fulfilled, (state, action) => {
        state.loading = false
        state.optionsPickup = action.payload
      })
      builder.addCase(requestLoadOptionsDropoff.fulfilled, (state, action) => {
        state.loading = false
        state.optionsDropoff = action.payload
      })
    }
});
export const userState = (state) => state.userState;

export const { setCurrentSearch, setFilter } = userSlice.actions;

export default userSlice.reducer;
