import { LoginScreen } from './index'

export const authRouteList = [
  {
    path: '/login',
    element: (
      <LoginScreen />
    )
  }
]
