import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { setFilter } from "../../../../redux/slices/userSlice";
import CardCoach from "../components/CardCoach";
import ModalOrder from "../components/ModalOrder";
import { Card, Typography, Radio, Space, Row, Checkbox, Button } from "antd";
import "./style.css";
import NotFound from "../components/NotFound";
import { MS } from "../../../../common/enum";
const { Title } = Typography;
import dayjs from "dayjs";

const CoachResult = () => {
	const dispatch = useAppDispatch()
	const listCompany = useAppSelector((state) => state.userState.listCompany);
	const filter = useAppSelector((state) => state.userState.filter)
	const [modalShow, setModalShow] = useState(false);
	const [currentCoach, setCurrentCoach] = useState();
	const [checkList, setCheckList] = useState([]);
	const [options, setOptions] = useState([]);
	const [valueRadio, setValueRadio] = useState(0);
	const [listRes, setListRes] = useState([]);
	const result = useAppSelector((state) => state.userState.result);
	const onChange = async (list) => {
		setCheckList(list);
		if (list.length) {
			const tmp = filter ? listRes.filter((rs) => list.includes(rs.coachCompany.name) == 1) : result.filter((rs) => list.includes(rs.coachCompany.name) == 1);
			setListRes(tmp);
			dispatch(setFilter(true))
		} else {
			dispatch(setFilter(false))
		}
	};

	useEffect(() => {
		const tmp = listCompany.map((com) => com.name);
		setOptions(tmp);
	}, [listCompany]);

	const handleSort = async(value) => {
		switch(value) {
			case 1: 
				dispatch(setFilter(false))
				break
			case 2: 
				const case_2 = filter ? listRes.slice().sort(function(a, b){
					return b.departureTime - a.departureTime;
				}) : result.slice().sort(function(a, b){
					return b.departureTime - a.departureTime;
				})
				setListRes(case_2)
				dispatch(setFilter(true))
				break
			
			case 3: 
				const case_3 = filter ? listRes.slice().sort(function(a, b){
					return a.departureTime - b.departureTime;
				}) : result.slice().sort(function(a, b){
					return a.departureTime - b.departureTime;
				})
				setListRes(case_3)
				dispatch(setFilter(true))
				break
			case 4:
				const case_4 = filter ? listRes.slice().sort(function(a, b){return a.price - b.price}) : result.slice().sort(function(a, b){return a.price - b.price})
				setListRes(case_4)
				dispatch(setFilter(true))
				break
			case 5: 
				const case_5 = filter ? listRes.slice().sort(function(a, b){return b.price - a.price}) : result.slice().sort(function(a, b){return b.price - a.price})
				setListRes(case_5)
				dispatch(setFilter(true))
				break
		}
	}

	const handleFilterTime = (value) => {
		switch(value) {
			case 1: 
				const case_1 = filter ? listRes.slice() : result.slice()
				const tmp = result.filter(s => s.departureTime / MS > -1 && s.departureTime / MS < 6)
				setListRes(tmp)
				dispatch(setFilter(true))
				break
			case 2: 
				const case_2 = filter ? listRes.slice() : result.slice()
				const tmp_2 = result.filter(s => s.departureTime / MS > 5 && s.departureTime / MS < 12)
				setListRes(tmp_2)
				dispatch(setFilter(true))
				break
			case 3: 
				const case_3 = filter ? listRes.slice() : result.slice()
				const tmp_3 = result.filter(s => s.departureTime / MS > 11 && s.departureTime / MS < 18)
				setListRes(tmp_3)
				dispatch(setFilter(true))
				break	
			case 4: 
				const case_4 = filter ? listRes.slice() : result.slice()
				const tmp_4 = result.filter(s => s.departureTime / MS > 17 && s.departureTime / MS < 24)
				setListRes(tmp_4)
				dispatch(setFilter(true))
				break		
		}
	}

	return (
		<div className="flex flex-row mobile: space-x-0 desktop:space-x-8 bg-neutral">
			<div className="w-1/3 space-y-4 mobile:hidden desktop:flex flex-col">
				<Card>
					<Title level={4}>Sắp xếp</Title>
					<Radio.Group defaultValue={1} onChange={(e) => handleSort(e.target.value)}>
						<Space direction="vertical">
							<Radio value={1}>Mặc định</Radio>
							<Radio value={2}>Giờ đi muộn nhất</Radio>
							<Radio value={3}>Giờ đi sớm nhất</Radio>
							<Radio value={4}>Giá vé tăng dần</Radio>
							<Radio value={5}>Giá vé giảm dần</Radio>
						</Space>
					</Radio.Group>
				</Card>
				<Card>
					<Title level={4}>Bộ lọc</Title>
					<div>
						<Title level={5}>Giờ đi</Title>
						<Radio.Group
							onChange={(e) => {
								handleFilterTime(e.target.value);
							}}
							className="space-y-2">
							<Row className="space-x-2">
								<Radio.Button value={1}>00:00-06:00</Radio.Button>
								<Radio.Button value={2}>06:00-12:00</Radio.Button>
							</Row>
							<Row className="space-x-2">
								<Radio.Button value={3}>12:00-18:00</Radio.Button>
								<Radio.Button value={4}>18:00-00:00</Radio.Button>
							</Row>
						</Radio.Group>
					</div>
					<div className="mt-4">
						<Title level={5}>Nhà xe</Title>
						<Checkbox.Group options={options} onChange={onChange} value={checkList} />
					</div>
				</Card>
			</div>
			<div className="mobile:w-full desktop:w-3/4 space-y-4">
				{result.length ? (
					filter ? (
						listRes.map((r, key) => (
							<CardCoach
								coach={r}
								currentCoach={currentCoach}
								setCurrentCoach={setCurrentCoach}
								modalShow={modalShow}
								setModalShow={setModalShow}
								key={key}
							/>
						))
					) : (
						result.map((r, key) => (
							<CardCoach
								coach={r}
								currentCoach={currentCoach}
								setCurrentCoach={setCurrentCoach}
								modalShow={modalShow}
								setModalShow={setModalShow}
								key={key}
							/>
						))
					)
				) : (
					<NotFound />
				)}
				{modalShow && <ModalOrder modalShow={modalShow} setModalShow={setModalShow} currentCoach={currentCoach} setCurrentCoach={setCurrentCoach} />}
			</div>
		</div>
	);
};

export default CoachResult;
