import { Button, DatePicker, Cascader } from "antd";
import { BluePoint, RedPoint, Calendar } from "../../../../assets/svgs";
import { SmallDashOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { requestFindCoach, requestLoadOptionsPickup, requestLoadOptionsDropoff, setCurrentSearch } from "../../../../redux/slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { locale } from "../../../../utils/regex";
import "./style.css";
import "moment/locale/vi";
import Popup from "../../../../screens/app/user/components/Popup";

moment.locale("vi");

const FindCoach = () => {
	const location = useLocation();
	const pathName = location.pathname;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const optionsPickup = useAppSelector(state => state.userState.optionsPickup)
	const optionsDropoff = useAppSelector(state => state.userState.optionsDropoff)
	const [pickPoint, setPickPoint] = useState(0);
	const [dropPoint, setDropPoint] = useState(0);
	const [startPoint, setStartPoint] = useState("");
	const [endPoint, setEndPoint] = useState("");
	const [startTime, setStartTime] = useState(0);
	const [endTime, setEndTime] = useState(0);
	const [popup, setPopup] = useState(false);
	const [count, setCount] = useState(0);
	const displayRender = (labels) => labels[labels.length - 1];
	
	const handleFindCoach = async () => {
		const data = {
			departureTime: startTime == 0 ? dayjs().valueOf() : startTime,
			endPointId: dropPoint,
			returnTime: endTime,
			startPointId: pickPoint,
		};
		const res = await dispatch(requestFindCoach(data));
		const tmp = unwrapResult(res);
		let searchInfo = {
			startPoint: startPoint,
			endPoint: endPoint,
			time: startTime == 0 ? dayjs().valueOf() : startTime,
		};
		dispatch(setCurrentSearch(searchInfo));
		// if (tmp.routeList.length != 0) {
			navigate("/tim-kiem");
		// }
		dispatch(requestLoadOptionsDropoff(""))
    	dispatch(requestLoadOptionsPickup(""))
	};
	const disabledDate = (current) => {
		const fifteenDaysFromNow = moment().add(14, "days");
		return (current && current < moment().startOf("day")) || current > fifteenDaysFromNow.endOf("day");
	};
	const setLocation = async (start, point) => {
		if (start) {
			dispatch(requestLoadOptionsDropoff(point.value))
			setStartPoint(point.label);
			setPickPoint(point.value);
		} else {
			dispatch(requestLoadOptionsPickup(point.value))
			setEndPoint(point.label);
			setDropPoint(point.value);
		}
	};

	return (
		<div
			className={`space-y-2 mobile:w-11/12 desktop:w-2/3 absolute mobile:-bottom-48 desktop:h-20 rounded-full z-10 ${
				pathName != "/" ? "mobile:hidden desktop:block desktop:top-8" : "desktop:bottom-4"
			}`}>
			<div
				className="flex flex-row w-full bg-white rounded-md p-1 items-center justify-center space-x-3 mobile:hidden desktop:flex"
				onClick={() => {
					setCount(count + 1);
					count < 1 ? setPopup(true) : null;
				}}>
				<div className="point-input w-1/4 flex flex-row items-center border border-black rounded-md px-1">
					<BluePoint />
					<Cascader
						placement="topLeft"
						popupClassName="custom-cascader-popup"
						showSearch={true}
						expandIcon={<div />}
						size="large"
						options={optionsPickup}
						placeholder="Nhập điểm đón"
						displayRender={displayRender}
						expandTrigger="hover"
						onChange={(value, label) => (value ? setLocation(true, label[1]) : null)}
					/>
				</div>
				<div onClick={() => {}}>
					<ArrowRightOutlined />
				</div>
				<div className="point-input w-1/4 flex flex-row items-center border border-black rounded-md px-1">
					<RedPoint />
					<Cascader
						placement="topLeft"
						popupClassName="custom-cascader-popup"
						showSearch={true}
						size="large"
						options={optionsDropoff}
						placeholder="Nhập điểm trả"
						displayRender={displayRender}
						expandTrigger="hover"
						expandIcon={<div />}
						onChange={(value, label) => (value ? setLocation(false, label[1]) : null)}
					/>
				</div>
				<div className="point-input w-1/4 flex flex-row items-center border border-black rounded-md px-1">
					<Calendar />
					<DatePicker
						placement="topRight"
						inputReadOnly={true}
						locale={locale}
						format="DD/MM/YY"
						suffixIcon={<div />}
						disabledDate={disabledDate}
						size="large"
						onChange={(value) => setStartTime(new Date(value).valueOf())}
						placeholder="Chọn ngày đi"
					/>
				</div>
				<Button onClick={() => handleFindCoach()} className="text-xl font-extrabold mobile:hidden desktop:block rounded-md h-4/5 w-1/5">
					Tìm xe
				</Button>
				{/* <DatePicker onChange={value => setEndTime(new Date(value).valueOf())} placeholder='Chọn ngày về'/> */}
				{/* <Col>
                        <p>Khứ hồi</p>
                        <Switch defaultChecked/>
                    </Col> */}
			</div>
			<div
				className="flex flex-col justify-center items-center bg-white rounded-md desktop:hidden mobile-fc border border-background"
				onClick={() => {
					setCount(count + 1);
					count < 1 ? setPopup(true) : null;
				}}>
				<div className="flex flex-row w-3/4 mt-2">
					<BluePoint />
					<Cascader
						popupClassName="custom-cascader-popup"
						size="large"
						suffixIcon={<div />}
						options={optionsPickup}
						placeholder="Nhập điểm đón"
						displayRender={displayRender}
						expandTrigger="hover"
						expandIcon={<div />}
						allowClear={false}
						onChange={(value, label) => setLocation(true, label[1])}
					/>
				</div>
				<div className="flex flex-row items-center h-6 w-3/4 ml-4">
					<SmallDashOutlined rotate={90} />
					<div className="w-3/4 mt-2 h-1 border-t border-green opacity-20"></div>
					{/* <Swap /> */}
				</div>

				<div className="flex flex-row w-3/4">
					<RedPoint />
					<Cascader
						popupClassName="test custom-cascader-popup"
						size="large"
						suffixIcon={<div />}
						options={optionsDropoff}
						placeholder="Nhập điểm trả"
						displayRender={displayRender}
						expandTrigger="hover"
						expandIcon={<div />}
						allowClear={false}
						onChange={(value, label) => setLocation(false, label[1])}
					/>
				</div>
				<div className="w-3/4 mt-2 h-1 border-t border-green opacity-20"></div>
				<div className="flex flex-row w-3/4 my-2 calendar-mobile">
					<Calendar />
					<DatePicker
						showToday={false}
						inputReadOnly={true}
						locale={locale}
						format="DD/MM/YY"
						className="items-center justify-center w-full"
						suffixIcon={<div />}
						disabledDate={disabledDate}
						onChange={(value) => setStartTime(new Date(value).valueOf())}
						placeholder="Chọn ngày đi"
					/>
				</div>
			</div>

			<div className="mobile:h-12 desktop:hidden flex items-center justify-center">
				<Button onClick={() => handleFindCoach()} className="text-xl font-extrabold h-12 w-full mt-6">
					Tìm xe
				</Button>
			</div>
			{popup && <Popup modalShow={popup} setModalShow={setPopup} />}
		</div>
	);
};

export default FindCoach;
