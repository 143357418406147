import banner from '../../../../assets/banner.png'
import FindCoach from '../FindCoach'
import { useAppSelector } from '../../../../redux/hook'
import { useLocation } from 'react-router-dom'

const Banner = () => {
    const location = useLocation()
    const pathName = location.pathname
    const listMainBanner = useAppSelector(state => state.newsState.mainBanner)
    return (
        <div className={`flex items-center flex-row justify-center relative`}>
            <div className={`${pathName != '/' ? 'hidden' : ''} flex items-center flex-col relative`} style={{backgroundColor:'#f3f3f3'}}>
                {
                    listMainBanner?.length ? <img src={listMainBanner[listMainBanner.length-1].imageUrl}/> : <img src={banner} className='max-w-full'/>
                }
            </div>
            <FindCoach />
        </div>
           
    ) 
}

export default Banner