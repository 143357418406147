import { useEffect, useState } from "react"
import { Seat } from "./seat"
import { Modal } from "antd"
import { apiGetSeatList, apiOrderCoach } from "../../../../../api/services"
import ModalSuccess from "../ModalSuccess";

export const ChooseSeat = (props) => {
    const [seatList, setSeatList] = useState([])
    const [chosed, setChosed] = useState([])
    const [success, setSuccess] = useState(false);
    const { modalShow, setModalShow, timeslotId, data, currentCoach } = props 
    const res = [0, 1, 2, 3, null, 4, 5, null, 6, 7, 8 ,9]
    const orderCoach = async () => {
        const props = { ...data, seatList: seatList}
        const res = await apiOrderCoach(props)
        if (res.data.error == 0) {
			setSuccess(true);
		}
    }
    
    const getSeatList = async () => {
        const res = await apiGetSeatList(timeslotId)
        if(!res.data.error) {
            setChosed(res.data.data.map(tmp => tmp.number))
        }
    }
    useEffect(() => {
        getSeatList()
    }, [])

    return (
        <div>
            <Modal
             open={modalShow}
             onCancel={() => setModalShow(false)}
             onOk={() => orderCoach()}
             cancelText="Quay lại"
             okText="Xác nhận"
             width={320}
            >
                <div className="space-y-3">
                    <div>
                        <p>{`Đã chọn (${seatList.length}/${data.quantity})`}</p>
                    </div>
                    <div className="border border-dashed border-green w-full mt-8" />
                    <div className="grid grid-cols-3 gap-2">
                        {
                            res.map(seat => <Seat num={seat} timeslotId={timeslotId} setSeatList={setSeatList} seatList={seatList} chosed={chosed} setChosed={setChosed} quantity={data.quantity}/>)
                        }
                    </div>
                </div>
                
            </Modal>
            {success && (
				<ModalSuccess
					modalShow={success}
					setModalShow={setSuccess}
					setFatherClose={setModalShow}
					isOrder={true}
					name={"sss"}
				/>
			)}
        </div>
    )
}