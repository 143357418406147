import { Modal, Typography } from "antd";
import { useEffect, useState } from "react";
const { Title } = Typography;
import { Success } from "../../../../../assets/svgs";
import "./style.css";

const ModalSuccess = (props) => {
	const { modalShow, setModalShow, setFatherClose, isOrder, name } = props;

	return (
		<Modal
			className="modalSuccess"
			open={modalShow}
			footer={(_, { OkBtn, CancelBtn }) => <div></div>}
			onCancel={() => {
				setModalShow(false);
				setFatherClose(false);
			}}
			width={800}
			centered>
			{!isOrder ? (
				<div className="flex flex-col justify-center items-center">
					<Success />
					<Title level={3}>Cảm ơn bạn đã liên hệ!</Title>
					<Title level={3}>Chúng tôi sẽ liên hệ với bạn trong vòng 24h tới.</Title>
				</div>
			) : (
				<div className="flex flex-col justify-center items-center">
					<Success />
					<Title level={3}>Cảm ơn bạn đã đặt vé!</Title>
					<Title level={4} className="text-center text-md md:text-xl">
						<span className="text-green font-bold">{`${name}`}</span> sẽ sớm liên hệ với bạn để xác nhận vé.
					</Title>
				</div>
			)}
		</Modal>
	);
};

export default ModalSuccess;
