import { ClockCircleOutlined, MinusOutlined, PhoneFilled, PlusOutlined, UserOutlined, PhoneOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { apiOrderCoach } from "../../../../../api/services";
import { IconCar, IconTP, MiniBlue, MiniRed } from "../../../../../assets/svgs/index";
import { useAppSelector } from "../../../../../redux/hook";
import convert from "../../../../../utils/convert";
import { locale, regexNumber } from "../../../../../utils/regex";
import ModalSuccess from "../ModalSuccess";
import { ChooseSeat } from "../ChooseSeat";
import ModalTravelPath from "../ModalTravelPath";
import OfficeAtPoint from "../OfficeAtPoint";
import { convertSecondsToDayjs } from "../../../../../utils/convert";

import "./style.css";
const { Title } = Typography;

const ModalOrder = (props) => {
	const [data, setData] = useState({})
	const [numberTicket, setNumberTicket] = useState(1);
	const [date, setDate] = useState();
	const [success, setSuccess] = useState(false);
	const [chooseSeat, setChooseSeat] = useState(false)
	const { currentCoach, setCurrentCoach, modalShow, setModalShow } = props;
	const [form] = Form.useForm();
	const searchInfo = useAppSelector((state) => state.userState.search);
	const [tpShow, setTpShow] = useState(false);
	const [startPointOffice, setStartPointOffice] = useState(false);
	const [endPointOffice, setEndPointOffice] = useState(false);

	useEffect(() => {
		form.setFieldValue("quantity", 1);
		form.setFieldValue("departureDate", dayjs(searchInfo?.time));
		setDate(searchInfo?.time);
	}, []);

	const orderCoach = async () => {
		// setChooseSeat(true)
		const tmp = form.getFieldsValue();
		const sss = {
			...tmp,
			quantity: numberTicket,
			timeslotId: currentCoach.timeslotId,
			pickUpPointId: currentCoach.startPoint.locationId,
			dropOffPointId: currentCoach.endPoint.locationId,
			departureDate: date,
			sectionId: currentCoach.sectionId,
		};
		setData(sss)
		const res = await apiOrderCoach(sss);
		if (res.data.error == 0) {
			setSuccess(true);
		}
	};

	const handleUpdateStatistic = async () => {
		const res = await apiUpdateStatistic({
			coachCompanyId: currentCoach.coachCompany.id,
			isHotlineClicked: true
		})
	}

	return (
		<div>
			<Modal open={modalShow} footer={() => <div></div>} onCancel={() => setModalShow(false)} width={720}>
				<Form form={form} onFinish={orderCoach}>
					{/* Header */}
					<div className="flex flex-row items-center h-18">
						<img src={currentCoach.coachCompany.logo} className="w-16 h-16 rounded-full" />
						<div className="flex flex-col ml-3 h-12">
							<Title level={4}>{currentCoach.coachCompany.name}</Title>
							<div className="flex flex-row space-x-2" onClick={() => handleUpdateStatistic()}>
								<PhoneFilled />
								<p className="font-bold">Hotline: {currentCoach.coachCompany.hotline}</p>
							</div>
						</div>
					</div>
					<div className="border border-dashed border-green my-3 w-full" />

					{/* Body */}
					<div className="flex flex-row items-center h-12 space-x-4">
						<Title className="w-24 text-center" style={{ color: "#006D38", fontWeight: "600", fontSize: "36px" }}>
							{dayjs(convertSecondsToDayjs(currentCoach.departureTime)).format("HH:mm")}
						</Title>
						<div className="flex flex-col text-sm font-semibold">
							<div className="space-x-2 flex flex-row items-center">
								<IconCar />
								<p className="text-base font-bold">{currentCoach.coach.coachType.name}</p>
							</div>
							<div className="space-x-2 flex flex-row items-center">
								<ClockCircleOutlined />
								<p className="text-base font-bold">
									{`${currentCoach.sectionId ? "Xe sẽ đón +/- 20 phút" : `Xuất bến ${currentCoach.startPoint.location.district}`}`}
								</p>
							</div>
						</div>
					</div>
					<div className="flex flex-row items-center space-x-2 md:space-x-4">
						<div className="w-16 md:w-24 flex-shrink-0 text-center">
							<img src={currentCoach?.coach?.picture} className="w-full rounded" />
						</div>
						<div className="flex flex-col py-4">
							<div className="space-x-1 flex flex-row items-center text-xs sm:text-sm">
								<IconTP className="inline-block w-3 mr-1" />
								<p className="truncate max-w-[30%] sm:max-w-[50%] ">
									<span className="font-extrabold">Lộ trình: </span>
									{currentCoach.travelPath.name}
								</p>
								<a className="text-green cursor-pointer" onClick={() => setTpShow(true)}>
									Chi tiết
								</a>
							</div>
							<div className="space-x-1 flex flex-row items-center text-xs sm:text-sm">
								<MiniBlue className="inline-block w-3 mr-1" />
								<p className="truncate max-w-[30%] sm:max-w-[50%] ">
									<span className="font-extrabold">{currentCoach.startPoint.location.district}: </span>
									{currentCoach.startPoint.description}
								</p>
								<a className="text-green" onClick={() => setStartPointOffice(true)}>
									Xem thêm
								</a>
							</div>
							<div className="space-x-1 flex flex-row items-center text-xs sm:text-sm">
								<MiniRed className="inline-block w-3 mr-1" />
								<p className="truncate max-w-[30%] sm:max-w-[50%] ">
									<span className="font-extrabold">{currentCoach.endPoint.location.district}: </span>
									{currentCoach.endPoint.description}
								</p>
								<a className="text-green flex-shrink-0" onClick={() => setEndPointOffice(true)}>
									Xem thêm
								</a>
							</div>
						</div>
					</div>
					<div className="border border-dashed border-green my-3 w-full" />
					<Row gutter={[8, 8]}>
						<Col xs={24} md={12}>
							<label>Ngày đi</label>
							<Form.Item name="departureDate" className="flex flex-col mb-3" rules={[{ required: true, message: "Nhập ngày xuất phát" }]}>
								<DatePicker
									format="DD/MM/YY"
									value={0}
									inputReadOnly={true}
									size="large"
									placeholder="Chọn ngày đi"
									locale={locale}
									className="w-full"
									style={{ borderRadius: "0.5rem" }}
									onChange={(date) => setDate(date.valueOf())}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<label>Số lượng vé</label>
							<Form.Item name="quantity" className="mb-3" rules={[{ required: true, message: "Nhập số lượng vé!" }]}>
								<div className="flex flex-row">
									<Space.Compact className="w-full rounded-md space-x-2 p-1" style={{ backgroundColor: "#F3F3F3" }}>
										<MinusOutlined
											className="pl-2 pr-1"
											onClick={() => (numberTicket > 1 ? setNumberTicket(numberTicket - 1) : setNumberTicket(1))}
										/>
										<Input
											size="small"
											className="ticket justify-center items-center"
											min={1}
											value={numberTicket}
											onChange={(e) => setNumberTicket(e.target.value)}
										/>
										<PlusOutlined
											className="pr-2 pl-1"
											onClick={() => (numberTicket < 10 ? setNumberTicket(numberTicket + 1) : setNumberTicket(numberTicket))}
										/>
									</Space.Compact>
								</div>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[8, 8]}>
						<Col xs={24} md={12}>
							<Form.Item name="passengerName" className="mb-3" rules={[{ required: true, message: "Vui lòng nhập họ tên" }]}>
								<Input size="large" className="rounded-lg" placeholder="Nhập họ tên" prefix={<UserOutlined className="mr-2" />} />
							</Form.Item>
						</Col>

						<Col xs={24} md={12}>
							<Form.Item name="phoneNumber" className="mb-3" rules={[{ required: true, message: "Vui lòng nhập số điện thoại!" }]}>
								<Input size="large" className="rounded-lg" placeholder="Nhập số điện thoại" prefix={<PhoneOutlined className="mr-2" />} />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item name="note">
						<Input size="large" className="rounded-lg" placeholder="Nhập ghi chú" prefix={<FileTextOutlined className="mr-2" />} />
					</Form.Item>

					{/* Footer */}
					<div className="flex flex-row items-center justify-end space-x-6 bg-white fixed left-0 right-0 bottom-0 shadow-[0_-5px_5px_-5px_#333] p-3 md:relative md:right-auto md:left-auto md:bottom-auto md:shadow-none md:p-0 mt-8">
						<div className="text-right">
							<p style={{ fontSize: "16px" }}>{`Giá vé: ${regexNumber(currentCoach.price)}đ`}</p>
							<label style={{ fontSize: "18px" }}>
								Tổng cộng: <a>{regexNumber(currentCoach.price * numberTicket)}đ</a>
							</label>
						</div>
						<Form.Item className="mb-0">
							<Button htmlType="submit" className="flex justify-center items-center h-14 w-35 md:w-40 text-lg px-5">
								Xác nhận
							</Button>
						</Form.Item>
					</div>
				</Form>
			</Modal>

			{success && (
				<ModalSuccess
					modalShow={success}
					setModalShow={setSuccess}
					setFatherClose={setModalShow}
					isOrder={true}
					name={currentCoach.coachCompany.name}
				/>
			)}
			{
				chooseSeat && <ChooseSeat modalShow={chooseSeat} setModalShow={setChooseSeat} timeslotId={currentCoach.timeslotId} data={data} currentCoach={currentCoach}/>
			}
			{tpShow && <ModalTravelPath modalShow={tpShow} setModalShow={setTpShow} tp={currentCoach.travelPath.detail} />}
			{startPointOffice && <OfficeAtPoint data={currentCoach.startPoint} modalShow={startPointOffice} setModalShow={setStartPointOffice} />}
			{endPointOffice && <OfficeAtPoint data={currentCoach.endPoint} modalShow={endPointOffice} setModalShow={setEndPointOffice} />}
		</div>
	);
};

export default ModalOrder;
