import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
import {IconNotFound} from '../../../../../assets/svgs/index'
const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col items-center pt-12 w-full'>
            <IconNotFound />
            <Result
            icon={null}
                subTitle={<div className='flex flex-col justify-center'>
                    <p>Chưa có tuyến xe thời điểm hiện tại.</p>
                    </div>}
                extra={<Button type="primary" onClick={() => navigate("/")}>Quay lại</Button>}
        />
        </div>
    )
}

export default NotFound