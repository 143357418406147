import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { apiGetNewsFeedById } from "../../../../../api/services"

export const NewsDetail = () => {
    const params = useParams()
    const [news, setNews] = useState({})

    const loadData = async () => {
        const res = await apiGetNewsFeedById(params.id)
        if(!res.data.error) {
            setNews(res.data.data)
        }
    }

    useEffect(() => {
        loadData()
    }, [])
    
    return (
        <div>
            <i>{news.description}</i>
            <div dangerouslySetInnerHTML={{ __html: news.content }}>
            </div>
        </div>
       
    )
}