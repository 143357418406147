import { apiChooseSeat, apiGetSeatList, apiUnChooseSeat } from "../../../../../api/services"
import { Steering } from "../../../../../assets/svgs"
import { useState } from "react"
import { useSnackbar } from "notistack"

export const Seat = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const { num, timeslotId, setSeatList, seatList, chosed, setChosed, quantity } = props
    const [onChose, setOnchose] = useState([])
    const handleChooseSeat = async() => {
        const res = await apiChooseSeat({
            number: num,
            timeslotId: timeslotId
        })
        if(res.data.data) {
            setSeatList([...seatList, {number: num}])
            setOnchose([...onChose, num])
        } else {
            setChosed([...chosed, num])
            enqueueSnackbar("Chỗ ngồi đã được chọn!", {
                variant: "error"
            })
        }
    }

    const handleUnchooseSeat = async () => {
        const res = await apiUnChooseSeat({
            number: num,
            timeslotId: timeslotId
        })
        if(!res.data.error) {
            const tmp1 = onChose.filter(n => n != num)
            const tmp = seatList.filter(seat => seat.number != num)
            setOnchose(tmp1)
            setSeatList(tmp)
        }
    }
    const render = () => {
        switch(num) {
            case 0: return <Steering />
            case null: return <div></div>
            case num : return (
                <div>
                    {
                        chosed.includes(num) ? <div className={`bg-neutral rounded-lg w-16 h-12 flex items-center justify-center cursor-pointer`}>
                            <div className="text-2xl cursor-pointer">{num}</div>
                        </div> : <div className={`${onChose.includes(num) ? 'bg-red' : 'null'} bg-green rounded-lg w-16 h-12 flex items-center justify-center cursor-pointer`} onClick={onChose.includes(num) ? handleUnchooseSeat : (quantity > seatList.length ? handleChooseSeat : null)}>
                            <div className="text-2xl text-white cursor-pointer">{num}</div>
                        </div>
                    }
                </div>
            )
        }
    }

    return render()
}