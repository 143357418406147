import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import newsReducer from './slices/newsSlice'
import userReducer from './slices/userSlice'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['authReducer']
}
const rootReducer = combineReducers({
    authState: authReducer,
    newsState: newsReducer,
    userState: userReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddle) => getDefaultMiddle({ serializableCheck: false }),
});

export const persistor = persistStore(store)
